<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-card-title>Permissões</b-card-title>
        <b-card-sub-title
          >Cadastre permissões do usuário de acordo com a
          necessidade.</b-card-sub-title
        >
      </b-card-body>
      <b-card-body>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Nome
              </label>
              <input
                v-model="form.name"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': $v.form.name.$error }"
              />
            </div>
          </div>
          <div class="col-md-4 d-flex-left">
            <div class="form-group mb-0">
              <label for="default-role" style="display: contents">
                <b-form-checkbox
                  class="custom-control-areas custom-control-success float-left custom-checkout-roles-default"
                  v-model="form.default"
                  switch
                  inline
                  :value="1"
                  id="default-role"
                >
                  <span class="switch-icon-left">
                    <i class="bi bi-check"></i>
                  </span>
                  <span class="switch-icon-right">
                    <i class="bi bi-x"></i>
                  </span>
                </b-form-checkbox>
                Usar perfil para novos usuários
              </label>
            </div>
          </div>
        </div>
      </b-card-body>

      <div class="table-container">
        <b-table
          striped
          responsive
          :items="form.permissions"
          class="mb-0 text-no-wrap table-permissions"
          :fields="tableColumns"
          fixed-header
        >
          <template #cell(subject)="data">
            <strong> {{ data.item.subject }}</strong>
          </template>
          <template #cell(action)="data">
            <div class="list-container">
              <div
                class="list-item"
                v-for="(action, index) in data.item.actions"
                :key="index"
              >
                <b-form-checkbox
                  :name="`${data.item.subject}_${action.value}`"
                  inline
                  class="checkbox-permission"
                  v-model="action.checked"
                  @change="checkboxChanged(data.item.subject, index)"
                >
                  {{ action.value }}
                </b-form-checkbox>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </b-card>
    <div class="form-row justify-content-end">
      <div class="col-md-3 col-12">
        <button
          type="button"
          :disabled="submited"
          class="btn btn-block btn-success"
          @click="submitUpdate"
        >
          <div v-if="submited">
            <b-spinner small variant="light" />
          </div>
          <div v-else>Salvar</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import { required, minLength } from "vuelidate/lib/validators";
import { permissionsDefault } from "@/auth/utils";

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BSpinner,
    ButtonsActionsFooter: () =>
      import("@/views/components/button/ButtonsActionsFooter"),
  },
  data() {
    return {
      uuid: "",
      submited: false,
      form: {
        name: "",
        default: false,
        permissions: [],
      },
      tableColumns: [
        {
          key: "subject",
          label: "Permissão",
          thStyle: "width: 200px",
        },
        {
          key: "action",
          label: "",
          class: "text-elipse-400",
        },
      ],
      permissions: permissionsDefault(),
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    async getData() {
      this.$store
        .dispatch("Permission/show", this.uuid)
        .then((res) => {
          this.form = res;
          const allowedActions = [];

          for (const userPermission of this.form.permissions) {
              const matchingPermission = this.permissions.find(permission =>
                  permission.subject === userPermission.subject
              );

              if (matchingPermission) {
                  const allowedActionsInCategory = userPermission.actions.filter(userAction => {
                      const matchingAction = matchingPermission.actions.find(action =>
                          action.value === userAction.value && action.checked
                      );
                      return matchingAction !== undefined;
                  });

                  allowedActions.push(...allowedActionsInCategory);
              }
          }

          return allowedActions;
        })
        .finally(() => {
          this.btnSearch = false;
        });
    },
    submitUpdate() {
      this.$v.form.$touch();

      if (!this.$v.form.$error) {
        this.submited = true;
        this.$store
          .dispatch("Permission/update", { uuid: this.uuid, data: this.form })
          .then(() => {
            this.notifyDefault("success");
            this.$router.push({ name: "config-permissions-list" });
          })
          .catch(() => {
            this.submited = false;
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      }
    },
    checkboxChanged(subject, actionIndex) {
      const permissionItem = this.form.permissions.find(
        (item) => item.subject === subject
      );

      if (permissionItem) {
        permissionItem.actions[actionIndex].checked = permissionItem.actions[actionIndex].checked;
      }
    },
  },
  mounted() {
    this.uuid = this.$route.params.uuid;
    this.getData();
  },
};
</script>
<style lang="scss">
.table-permissions {
  strong {
    font-size: 1rem !important;
    font-weight: 500;
  }
}
.table-container {
  overflow-x: auto;
}
.checkbox-permission {
  cursor: pointer;
  label {
    font-weight: 400;
  }
}
.list-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.custom-checkout-roles-default {
  .custom-control-label {
    font-size: 0.9rem !important;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    span {
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
