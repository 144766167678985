var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card-body",
            [
              _c("b-card-title", [_vm._v("Permissões")]),
              _c("b-card-sub-title", [
                _vm._v(
                  "Cadastre permissões do usuário de acordo com a necessidade."
                ),
              ]),
            ],
            1
          ),
          _c("b-card-body", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-4" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _c("i", { staticClass: "text-danger bi bi-record-circle" }),
                    _vm._v(" Nome "),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.name,
                        expression: "form.name",
                      },
                    ],
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.$v.form.name.$error },
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 d-flex-left" }, [
                _c("div", { staticClass: "form-group mb-0" }, [
                  _c(
                    "label",
                    {
                      staticStyle: { display: "contents" },
                      attrs: { for: "default-role" },
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass:
                            "custom-control-areas custom-control-success float-left custom-checkout-roles-default",
                          attrs: {
                            switch: "",
                            inline: "",
                            value: 1,
                            id: "default-role",
                          },
                          model: {
                            value: _vm.form.default,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "default", $$v)
                            },
                            expression: "form.default",
                          },
                        },
                        [
                          _c("span", { staticClass: "switch-icon-left" }, [
                            _c("i", { staticClass: "bi bi-check" }),
                          ]),
                          _c("span", { staticClass: "switch-icon-right" }, [
                            _c("i", { staticClass: "bi bi-x" }),
                          ]),
                        ]
                      ),
                      _vm._v(" Usar perfil para novos usuários "),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "table-container" },
            [
              _c("b-table", {
                staticClass: "mb-0 text-no-wrap table-permissions",
                attrs: {
                  striped: "",
                  responsive: "",
                  items: _vm.form.permissions,
                  fields: _vm.tableColumns,
                  "fixed-header": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(subject)",
                    fn: function (data) {
                      return [
                        _c("strong", [_vm._v(" " + _vm._s(data.item.subject))]),
                      ]
                    },
                  },
                  {
                    key: "cell(action)",
                    fn: function (data) {
                      return [
                        _c(
                          "div",
                          { staticClass: "list-container" },
                          _vm._l(data.item.actions, function (action, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "list-item" },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "checkbox-permission",
                                    attrs: {
                                      name:
                                        data.item.subject + "_" + action.value,
                                      inline: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.checkboxChanged(
                                          data.item.subject,
                                          index
                                        )
                                      },
                                    },
                                    model: {
                                      value: action.checked,
                                      callback: function ($$v) {
                                        _vm.$set(action, "checked", $$v)
                                      },
                                      expression: "action.checked",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(action.value) + " ")]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "form-row justify-content-end" }, [
        _c("div", { staticClass: "col-md-3 col-12" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-block btn-success",
              attrs: { type: "button", disabled: _vm.submited },
              on: { click: _vm.submitUpdate },
            },
            [
              _vm.submited
                ? _c(
                    "div",
                    [
                      _c("b-spinner", {
                        attrs: { small: "", variant: "light" },
                      }),
                    ],
                    1
                  )
                : _c("div", [_vm._v("Salvar")]),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }